.blurry-load {
  -webkit-filter: blur(20px);
  filter: blur(20px);
}
@-webkit-keyframes blurOut {
  0% {
    -webkit-filter: blur(20px);
    filter: blur(20px);
  }
  50% {
    -webkit-filter: blur(10px);
    filter: blur(10px);
  }
  to {
    -webkit-filter: blur(0);
    filter: blur(0);
  }
}
@keyframes blurOut {
  0% {
    -webkit-filter: blur(20px);
    filter: blur(20px);
  }
  50% {
    -webkit-filter: blur(10px);
    filter: blur(10px);
  }
  to {
    -webkit-filter: blur(0);
    filter: blur(0);
  }
}
.blur-out {
  -webkit-animation: blurOut 0.5s ease-out;
  animation: blurOut 0.5s ease-out;
}
@-webkit-keyframes shimmer {
  0% {
    background-position: -100vw 0;
  }
  to {
    background-position: 100vw 0;
  }
}
@keyframes shimmer {
  0% {
    background-position: -100vw 0;
  }
  to {
    background-position: 100vw 0;
  }
}
.no-blur {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: shimmer;
  animation-name: shimmer;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: gradient(
    linear,
    left top,
    right top,
    color-stop(8%, #eee),
    color-stop(18%, #ddd),
    color-stop(33%, #eee)
  );
  background: linear-gradient(90deg, #eee 8%, #ddd 18%, #eee 33%);
}
